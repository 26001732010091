import { Vue, Component, Prop } from 'vue-property-decorator';

import { RobotModel } from '@/models';
import { KIND_CLEANING, KIND_COMMISSIONING, KIND_MEDICAL, KIND_TRANSPORT } from '@/models/robot';

@Component
class RobotFacts extends Vue {
  protected readonly KIND_TRANSPORT = KIND_TRANSPORT;
  protected readonly KIND_MEDICAL = KIND_MEDICAL;
  protected readonly KIND_COMMISSIONING = KIND_COMMISSIONING;
  protected readonly KIND_CLEANING = KIND_CLEANING;

  @Prop()
  protected robot!: RobotModel;
}

export default RobotFacts;
