var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.classes},[_c('v-container',{class:[
      'section__container',
      _vm.wide ? 'section__container-wide' : '',
      _vm.wide && !_vm.noPaddings
        ? _vm.$vuetify.breakpoint.mdAndDown
          ? 'px-4'
          : 'px-12'
        : _vm.$vuetify.breakpoint.mdAndDown
        ? 'px-4'
        : 'px-0',
      _vm.$vuetify.breakpoint.mdAndDown && !_vm.noPaddings ? 'px-4' : 'px-0 py-0' ]},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }