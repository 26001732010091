import { RouteConfigSingleView, Route } from 'vue-router/types/router';
import map from 'lodash/map';

import * as views from '@/views';
import { LOCALES, DEFAULT_LOCALE, LOCALE_DE } from '@/constants/i18n';
import i18n, { i18nRoute } from '@/plugins/i18n';
import store from '@/store';

// this enforces trailing slashes
// https://github.com/vuejs/vue-router/issues/1753#issuecomment-386297647
const pathToRegexpOptions = { strict: true };

const localizedRoutes = [
  {
    path: '',
    name: 'home',
    component: views.Home,
    meta: { isPublic: true },
  },
  {
    path: 'about/',
    name: 'about',
    component: views.About,
    meta: { isPublic: true },
  },
  {
    path: 'login/',
    name: 'login',
    component: views.Login,
    meta: { isPublic: true },
  },
  {
    path: 'signup/',
    name: 'signup',
    component: views.Signup,
    meta: { isPublic: true },
  },
  {
    path: 'logistics-innovators-newsletter/',
    name: 'newsletter',
    component: views.Newsletter,
    meta: { isPublic: true },
  },
  {
    path: 'your-premium-profile/',
    name: 'premium-profile',
    component: views.PremiumProfileAd,
    meta: { isPublic: true },
  },
  {
    path: 'magazine-contact/',
    name: 'magazine-contact',
    component: views.MagazineContact,
    meta: { isPublic: true },
  },
  {
    path: 'imprint/',
    name: 'imprint',
    component: views.Imprint,
    meta: { isPublic: true },
  },
  {
    path: 'privacy/',
    name: 'privacy',
    component: views.Privacy,
    meta: { isPublic: true },
  },
  {
    path: 'terms/',
    name: 'terms',
    component: views.Terms,
    meta: { isPublic: true },
  },
  {
    path: 'robot-finder/',
    name: 'robot-finder',
    component: views.RobotFinder,
    meta: { isPublic: true },
  },
  {
    path: 'amr-agv-maintenance-asset-management-spare-parts-repairs/',
    name: 'care',
    component: views.Care,
    meta: { isPublic: true },
  },
  {
    path: 'manufacturer-finder/',
    name: 'manufacturer-finder',
    component: views.ManufacturerFinder,
    meta: { isPublic: true },
  },
  {
    path: 'company/:companySlug/',
    name: 'company',
    component: views.Company,
    meta: { isPublic: true },
  },
  {
    path: 'company/:companySlug/:productSlug/',
    name: 'product',
    component: views.Product,
    meta: { isPublic: true },
  },
  {
    path: 'product-finder/',
    name: 'product-finder',
    component: views.ProductFinder,
    meta: { isPublic: true },
  },
  {
    path: 'product-company-finder/',
    name: 'product-company-finder',
    component: views.ProductCompanyFinder,
    meta: { isPublic: true },
  },
  {
    path: 'service/:serviceSlug/',
    name: 'service',
    component: views.Service,
    meta: { isPublic: true },
  },
  {
    path: 'service-finder/',
    name: 'service-finder',
    component: views.ServiceFinder,
    meta: { isPublic: true },
  },
  {
    path: 'services/:country/:service',
    name: 'service-landing',
    component: views.ServiceLanding,
    meta: { isPublic: true },
  },
  {
    path: 'service-dictionary/',
    name: 'service-dictionary',
    component: views.ServiceDictionary,
    meta: { isPublic: true },
  },
  {
    path: 'category/:categorySlug/',
    name: 'category',
    component: views.Category,
    meta: { isPublic: true },
  },
  {
    path: 'consulting/',
    name: 'consulting',
    component: views.Consulting,
    meta: { isPublic: true },
  },
  {
    path: 'planner/',
    name: 'planner',
    component: views.Planner,
    meta: { isPublic: true },
  },
  {
    path: 'testimonials-success-stories-agv-amr-mobile-robots/',
    name: 'testimonials',
    component: views.Testimonials,
    meta: { isPublic: true },
  },
  {
    path: 'feedback/',
    name: 'feedback',
    component: views.Feedback,
    meta: { isPublic: true },
  },
  {
    path: 'robotics-market-insights-whitepaper/',
    name: 'whitepaper',
    component: views.Whitepaper,
    meta: { isPublic: true },
  },
  {
    path: 'fts-agv-amr-market-insights-report/',
    name: 'lob-report',
    component: views.LobReport,
    meta: { isPublic: true },
  },
  {
    // step 1
    path: 'accounts/password_reset/',
    name: 'password-reset',
    component: views.PasswordReset,
    meta: { isPublic: true },
  },
  {
    // step 2
    path: 'accounts/password_reset/done/',
    name: 'password-reset-done',
    component: views.PasswordResetDone,
    meta: { isPublic: true },
  },
  {
    // step 3
    path: 'accounts/password_reset/reset/:uidb64/:token/',
    name: 'password-reset-confirm',
    component: views.PasswordResetConfirm,
    meta: { isPublic: true },
  },
  {
    // step 4
    path: 'accounts/password_reset/complete/',
    name: 'password-reset-complete',
    component: views.PasswordResetComplete,
    meta: { isPublic: true },
  },
  {
    path: 'magazine/:blogPostSlug/',
    name: 'blog-post',
    component: views.BlogPost,
    meta: { isPublic: true },
  },
  {
    path: 'magazine/',
    name: 'blog',
    component: views.BlogIndex,
    meta: { isPublic: true },
  },
  {
    path: 'press/',
    name: 'press',
    component: views.PressIndex,
    meta: { isPublic: true },
  },
  {
    path: 'press/:pressPostSlug/',
    name: 'press-post',
    component: views.PressPost,
    meta: { isPublic: true },
  },
  {
    path: 'thank-you/:component/',
    name: 'thank-you',
    component: views.ThankYou,
    meta: { isPublic: true },
  },
  {
    path: 'landing/',
    name: 'landing',
    component: views.Landing,
    meta: { isPublic: true },
  },
  {
    path: 'guidelines/',
    name: 'guidelines',
    component: views.Guidelines,
    meta: { isPublic: true },
  },
  {
    path: 'glossary/',
    name: 'glossary',
    component: views.GlossaryIndex,
    meta: { isPublic: true },
  },
  {
    path: 'glossary/:glossaryEntrySlug/',
    name: 'glossary-entry',
    component: views.GlossaryEntry,
    meta: { isPublic: true },
  },
  {
    path: 'your-tenders/',
    name: 'tenders',
    component: views.Tenders,
  },
  {
    path: 'your-tenders/:tenderSlug/',
    name: 'tender',
    component: views.Tender,
  },
  {
    path: 'dashboard/',
    name: 'dashboard',
    component: views.Dashboard,
  },
  {
    path: 'dashboard-analytics/',
    name: 'dashboard-analytics',
    component: views.DashboardAnalytics,
  },
  {
    path: 'tools/',
    name: 'tools',
    component: views.Tools,
    meta: { isPublic: true },
  },
  {
    path: 'tools/new-robots/',
    name: 'tools-new-robots',
    component: views.ToolRobotNew,
    meta: { isPublic: true },
  },
  {
    path: 'tools/similar/',
    name: 'tools-similar-robots-index',
    component: views.ToolRobotSimilar,
    meta: { isPublic: true },
  },
  {
    path: 'tools/similar/:robotSlug/',
    name: 'tools-similar-robots',
    component: views.ToolRobotSimilar,
    meta: { isPublic: true },
  },
  {
    path: 'tools/compare/',
    name: 'tools-compare-robots',
    component: views.ToolRobotCompare,
    meta: { isPublic: true },
  },
  {
    path: 'tools/roi-calculator-amr-agv/',
    name: 'tools-roi',
    component: views.ToolROI,
    meta: { isPublic: true },
  },
  {
    path: ':robotSlug1([^/]+)-versus-:robotSlug2([^/]+)/',
    name: 'robot-comparison',
    component: views.H2HComparison,
    meta: { isPublic: true },
  },
  {
    path: 'top-:usecase([^/]+)-robots-by-:objective([^/]+)/',
    name: 'top-list',
    component: views.TopList,
    meta: { isPublic: true },
  },
  {
    path: 'explore/',
    name: 'explore',
    component: views.Explore,
    meta: { isPublic: true },
  },
  {
    path: 'faq/',
    name: 'faq',
    component: views.FAQIndex,
    meta: { isPublic: true },
  },
  {
    path: 'faq/:faqEntrySlug/',
    name: 'faq-entry',
    component: views.FAQEntry,
    meta: { isPublic: true },
  },
  {
    path: 'manufacturer-news/',
    name: 'manufacturer-news',
    component: views.ManufacturerNews,
    meta: { isPublic: true },
  },
  {
    path: 'manufacturer-news/:manufacturerNewsSlug/',
    name: 'manufacturer-news-post',
    component: views.ManufacturerNewsPost,
    meta: { isPublic: true },
  },
  {
    path: 'use-cases/:useCaseSlug/',
    name: 'use-case',
    component: views.UseCase,
    meta: { isPublic: true },
  },
  {
    path: 'company/:companySlug/product/create/',
    name: 'product:create',
    component: views.ProductCreate,
    meta: { isPublic: false },
  },
  {
    path: 'all-use-cases/',
    name: 'use-cases',
    component: views.UseCases,
    meta: { isPublic: true },
  },
  {
    path: 'all-services/',
    name: 'services',
    component: views.Services,
    meta: { isPublic: true },
  },
  {
    path: 'all-categories/',
    name: 'categories',
    component: views.Categories,
    meta: { isPublic: true },
  },
  {
    path: 'robot-marketplace/',
    name: 'robot-marketplace',
    component: views.RobotMarketplace,
    meta: { isPublic: true },
  },
  {
    path: 'robot-community-forum/',
    name: 'robot-community-forum',
    component: views.RobotCommunityForum,
    meta: { isPublic: true },
  },
  {
    path: ':manufacturerSlug/robots/create/',
    name: 'robot:create',
    component: views.RobotCreate,
    meta: { isPublic: false },
  },
  {
    path: ':manufacturerSlug/:robotSlug/',
    name: 'robot',
    component: views.Robot,
    meta: { isPublic: true },
  },
  {
    path: ':manufacturerSlug/',
    name: 'manufacturer',
    component: views.Manufacturer,
    meta: { isPublic: true },
  },
  {
    path: ':manufacturerSlug/news-page',
    name: 'manufacturer-news-page',
    component: views.ManufacturerNewsPage,
    meta: { isPublic: true },
  },
];

const localizeRoute = (locale: string, route: RouteConfigSingleView): RouteConfigSingleView => ({
  component: route.component,
  name: route.name ? `${locale}:${route.name}` : undefined,
  path: route.path ? i18n.t(route.path, locale).toString() : '',
  children: map(route.children || [], r => localizeRoute(locale, r)),
  pathToRegexpOptions,
  meta: route.meta || { isPublic: true },
});

const loginRoute = () => i18nRoute({ name: 'login' });

const routes = [
  ...map(LOCALES, (locale: string) => ({
    path: '/' + locale,
    component: views._blank,
    beforeEnter(to: Route, from: Route, next: (to?: any) => any) {
      // language
      const lang = to.path.substr(1, 2);

      if (!LOCALES.includes(lang)) {
        return next(i18nRoute({ name: 'home' }));
      }

      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }

      // authorization
      if (!to.meta.isPublic && !store.getters.isAuthenticated) {
        store
          .dispatch('getUser')
          .then(() => {
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next(loginRoute());
            }
          })
          .catch(() => {
            next(loginRoute());
          });
      } else {
        next();
      }
    },
    children: map(localizedRoutes, r => localizeRoute(locale, r)),
  })),

  {
    path: '*',
    component: views._blank,
    meta: { isPublic: true },
    beforeEnter(to: Route, from: Route, next: (to?: any) => any) {
      window.location.pathname = `/${i18n.locale}/`;
    },
  },
];

export default routes;
